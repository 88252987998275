/**
 * Generated Typopgraphic styles from @lib/typography/getTypographyStyles.js
 */
 html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  color: hsla(0, 0%, 0%, 0.8) !important;
  font-size: 1.8rem !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block
}

audio,
canvas,
progress,
video {
  display: inline-block
}

audio:not([controls]) {
  display: none;
  height: 0
}

progress {
  vertical-align: baseline
}

[hidden],
template {
  display: none
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted
}

b,
strong {
  font-weight: inherit;
  font-weight: bolder
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: .67em 0
}

mark {
  background-color: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

img {
  border-style: none
}

svg:not(:root) {
  overflow: hidden
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0
}

optgroup {
  font-weight: 700
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
  -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal
}

textarea {
  overflow: auto
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

html {
  font: 62.5%/1.61 'Lato', sans-serif;
  box-sizing: border-box;
  overflow-y: scroll;
}

* {
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

body {
  color: hsla(0, 0%, 0%, 0.8);
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-size: 1.8rem;
  line-height: 2.415rem;
}

img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 2.9124rem;
  line-height: 4.025rem;
}

h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 2.40248rem;
  line-height: 3.22rem;
}

h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 2.18205rem;
  line-height: 3.22rem;
}

h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 1.8rem;
  line-height: 2.415rem;
}

h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 1.63485rem;
  line-height: 2.415rem;
}

h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  color: inherit;
  font-family: 'Lato', sans-serif !important;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 0.86558rem;
  line-height: 1.1;
}

hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

ul {
  margin-left: 1.61rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  list-style-position: outside;
  list-style-image: none;
}

ol {
  margin-left: 1.61rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  list-style-position: outside;
  list-style-image: none;
}

dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  line-height: 2.4rem;
}

figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  font-size: 0.85rem;
  line-height: 1.61rem;
}

table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
  font-size: 1rem;
  line-height: 1.61rem;
  border-collapse: collapse;
  width: 100%;
}

fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

blockquote {
  margin-left: 1.61rem;
  margin-right: 1.61rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(2.898rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 2.898rem;
}

b {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

dt {
  font-weight: 700;
}

th {
  font-weight: 700;
}

li {
  margin-bottom: calc(2.898rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}

li>ol {
  margin-left: 1.61rem;
  margin-bottom: calc(2.898rem / 2);
  margin-top: calc(2.898rem / 2);
}

li>ul {
  margin-left: 1.61rem;
  margin-bottom: calc(2.898rem / 2);
  margin-top: calc(2.898rem / 2);
}

blockquote *:last-child {
  margin-bottom: 0;
}

li *:last-child {
  margin-bottom: 0;
}

p *:last-child {
  margin-bottom: 0;
}

li>p {
  margin-bottom: calc(2.898rem / 2);
}

code {
  font-size: 0.85rem;
  line-height: 1.61rem;
}

kbd {
  font-size: 0.85rem;
  line-height: 1.61rem;
}

samp {
  font-size: 0.85rem;
  line-height: 1.61rem;
}

abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}

acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}

abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}

thead {
  text-align: left;
}

td,
th {
  text-align: left;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding-left: 1.07333rem;
  padding-right: 1.07333rem;
  padding-top: 0.805rem;
  padding-bottom: calc(0.805rem - 1px);
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

ol,
ul {
  margin-left: 2.898rem;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.1;
}